<template>
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="'Rejeitar Indisponibilidade'"
      :modal="true"
      :closable="false"
      :contentStyle="{ overflow: 'visible' }"
    >
      <form name="rejectForm" class="p-col-12" @submit.prevent="confirmReject">
        <div class="p-fluid p-formgrid p-grid">
          <div
            class="p-field p-col-12 p-md-12"
            v-if="unavailabilityReject.unavailability"
          >
            {{ unavailabilityReject.unavailability.technician }} |
            {{ unavailabilityReject.unavailability.date }} |
            {{ unavailabilityReject.unavailability.start }} |
            {{ unavailabilityReject.unavailability.end }} |
            {{ unavailabilityReject.unavailability.resume }}
            {{ unavailabilityReject.unavailability.description }}
            {{ unavailabilityReject.unavailability.note }}
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                name="reason_reject"
                id="reason_reject"
                v-validate="'required'"
                :autoResize="true"
                rows="5"
                v-model="unavailabilityReject.reason_reject"
                v-bind:class="[
                  { 'p-invalid': errors.has('reason_reject') },
                  'form-control',
                ]"
              />
              <label for="aclName">Razão da Rejeição</label>
            </span>
            <small
              v-if="errors.has('reason_reject')"
              class="p-error"
              role="alert"
            >
              Razão da Rejeição é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-sm p-button-danger"
          @click="cancel()"
        />
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-sm p-button-success"
          @click="confirmReject()"
        />
      </template>
    </Dialog>
</template>
<script>
import bookingService from "../../services/booking.service";

export default {
  name: "UnavailabilityReject",
  props: ["show", "unavailabilityReject"],
  data() {
    return {
      reason: ""
    };
  },
  methods: {
    reject(unavailability) {
      this.unavailabilityReject.unavailability = unavailability;
      return (this.unavailabilityReject.show = true);
    },
    confirmReject() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        let params = {
          reason_reject: this.unavailabilityReject.reason_reject,
        };

        return bookingService
          .reject(this.unavailabilityReject.unavailability.id, params)
          .then((response) => {
            if (!response) {
              this.$toast.add({
                severity: "error",
                summary: "Erro",
                detail: "Ocorreu um erro ao rejeitar a insdisponibilidade",
                life: 3000,
              });
              return this.$emit("cancel");
            }

            this.$toast.add({
              severity: "success",
              summary: "Indisponibilidade Rejeitada",
              detail: "A Indisponibilidade foi rejeitada com sucesso",
              life: 3000,
            });
            return this.$emit("rejection");
          });
      });
    },
    cancel() {
      return this.$emit("cancel");
    },
  }
};
</script>