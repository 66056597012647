<template>
        <ConfirmDialog></ConfirmDialog>
</template>
  <script>
  import bookingService from "../../services/booking.service";

export default {
    name: "UnavailabilityApprove",
    props: ["show", "unavailabilityApprove"],
    methods: {
    accept(unavailability) {
      let message = `
        ${unavailability.technician} |
            ${unavailability.date} |
            ${unavailability.start} |
            ${unavailability.end} |
            ${unavailability.resume}
            ${unavailability.description}
            ${unavailability.note}`;
      this.$confirm.require({
        header: "Aprovar Indisponibilidade",
        message: message,
        icon: "pi pi-exclamation-triangle",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-sm p-button-danger p-button p-component",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-sm p-button-success p-button p-component",
        accept: () => {
          return bookingService.approve(unavailability.id).then((response) => {
            this.$confirm.close();
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro",
                detail: "Ocorreu um erro ao aprovar a insdisponibilidade",
                life: 3000,
              });
            }

            this.$toast.add({
              severity: "success",
              summary: "Indisponibilidade Aprovada",
              detail: "A Indisponibilidade foi aprovada com sucesso",
              life: 3000,
            });
            return this.$emit("accepted", response);
          });
        },
        reject: () => {
          this.$confirm.close();
          return this.$emit("cancel")
        }});
    },
  },
};
  </script>